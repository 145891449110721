<script setup lang="ts">
import Header from "~/components/layout/Header.vue";
import Discord from "~/components/layout/Discord.vue";
import Footer from "~/components/layout/Footer.vue";
import Spotify from "~/components/layout/Spotify.vue";
import Newsletter from "~/components/layout/Newsletter.vue";
import Promotion from "~/components/layout/Promotion.vue";
// import Announcement from "../components/layout/Announcement.vue";

const promotionsResponse = await GqlGetPromotions();
const promotions = promotionsResponse.promotions;
</script>

<template>
    <div class="sticky top-0 z-10">
        <Header />
        <Promotion class="max-sm:hidden block" v-if="promotions.length && promotions[0].active" :promotion="promotions[0]"/>
<!--        <Announcement class="max-sm:hidden block"/>-->
    </div>

    <Promotion class="sm:hidden" v-if="promotions.length && promotions[0].active" :promotion="promotions[0]"/>
<!--    <Announcement class="sm:hidden"/>-->

    <NuxtPage class="z-0"/>

    <Spotify/>
    <Discord/>
    <Newsletter/>
    <Footer/>
</template>

<style>
body {
    font-family: "Barlow Semi Condensed";
    width: 100%;
    overflow-x: hidden;
}
</style>
